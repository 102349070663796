import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  Grid, MenuItem, Checkbox, ListItemText,
} from '@material-ui/core';
import CreateTaskDialog from './CreateTaskDialog';
import {
  ENTITY_TYPE_FILE,
} from '../../core/entities';
import {
  createBlueStreamExportTask,
  fetchBlueStreamProjects,
  fetchBlueStreamPing,
  fetchBlueStreamProjectRootMaps,
} from '../../redux/actions/taskActions';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { checkFileExist, getObjectsFromPath, getPathEntity } from '../../redux/actions/fileStorageActions';
import { getCorrectPath } from '../common/Utility';
import { TASKS_ROUTE } from '../../core/constants';

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

const validationSchema = () => {
  let schema = {
    accessToken: yup.string().required('The access token is required'),
    projectId: yup.number()
      .typeError('The project ID value must be integer')
      .integer('The project ID value must be integer')
      .required('The project is required'),
    projectRootMaps: yup.array().of(yup.string())
      .required('The project root maps is required')
      .test({
        message: 'The "project root maps" values can not be empty',
        test: arr => arr.length >= 1,
      }),
    username: yup.string().required('The username is required'),
    baseUrl: yup.string().required('The base url is required'),
  };

  const taskExportSchema = {
    outputFilename: yup.string()
      .required('Output file name is required')
      .test(
        'start_end_with_space',
        'The output filename can not start or end with spaces',
        v => !(v.startsWith(' ') || v.endsWith(' ')),
      )
      .test(
        'allowed-characters',
      <>
        Output file name can contain only the next characters:
        <br />
        {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
      </>,
      v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
      )
      .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
      .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
      .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
      .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
    outputFolderPath: yup.string()
      .test(
        'required',
        'Output folder path is required',
        function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          return value && value !== '';
        },
      )
      .test(
        'start_end_with_space',
        'The element of the output folder path can not start or end with spaces',
        function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          let result = true;
          value.split('/').forEach(path => {
            if (path.startsWith(' ') || path.endsWith(' ')) {
              result = false;
            }
          });

          return result;
        },
      )
      .test(
        'allowed-characters',
      <>
        Output folder can contain only the next characters:
        <br />
        {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
      </>,
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
      },
      )
      .test(
        'not-new-lines',
        'Output folder name cannot contain the new lines',
        function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          return value && !value.includes('\n');
        },
      )
      .test(
        'not-contain-extension',
        'Output folder name cannot contain the extensions',
        function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          return value && !value.includes('.zip');
        },
      )
      .test(
        'folder-exist',
        'Output folder does not exist or is not a folder entity',
        async function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          if (value) {
            const parentResponse = await getPathEntity(value.split('/')[0]);
            if (!(
              parentResponse.status === 200
                && parentResponse.data.data.type !== ENTITY_TYPE_FILE
            )
            ) {
              return this.createError({ message: 'Output parent folder does not exist' });
            }

            const valueResponse = await getPathEntity(value);

            return (
              valueResponse.status === 200 && valueResponse.data.data.type !== ENTITY_TYPE_FILE
            );
          }

          return false;
        },
      ),
  };

  schema = Object.assign(schema, taskExportSchema);

  return yup.object(schema);
};

const generateInitValues = task => {
  const config = JSON.parse(task.config || '{}');

  const [outputFolderPath, outputFilename] = parsePath(task.output || '');

  return {
    // bluestream export
    projectId: '',
    projectRootMaps: [],

    username: config.username || '',
    baseUrl: config.base_url || '',

    accessToken: '',

    outputFolderPath,
    outputFolderPathObjects: [],
    outputFolderPathSearchValue: '',
    outputFilename: outputFilename || '',

    createFolders: false,
  };
};

function CreateBlueStreamDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [projects, setProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);

  const [projectRootMaps, setProjectRootMaps] = useState([]);
  const [loadingProjectRootMaps, setLoadingProjectRootMaps] = useState(false);

  const [firstStepError, setFirstStepError] = useState('');
  const [enabledService, setEnabledService] = useState(false);

  const [creatingTask, setCreatingTask] = useState(false);

  const [taskType, setTaskType] = useState(rerunTask.converter || '');

  const [outputFileWarning, setOutputFileWarning] = useState(undefined);

  const [loadingOutputFolderObjects, setLoadingOutputFolderObjects] = useState(false);

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema: validationSchema(taskType),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  const onFormSubmit = values => {
    setCreatingTask(true);

    let outputFolder = `${values.outputFolderPath}${values.outputFolderPath.endsWith('/') ? '' : '/'}`;
    if (values.outputFolderPathSearchValue !== '') {
      outputFolder += `${values.outputFolderPathSearchValue}${values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
    }

    const task = {
      output: `${outputFolder}${values.outputFilename}`,
      project_id: parseInt(values.projectId, 10),
      root_maps: values.projectRootMaps,
      access_token: values.accessToken,
      username: values.username,
      base_url: values.baseUrl,
    };

    dispatch(createBlueStreamExportTask(task))
      .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
      .catch(() => setCreatingTask(false));
  };

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  useEffect(() => {
    if (open) {
      setLoadingOutputFolderObjects(true);

      const value = getCorrectPath(formik.values.outputFolderPath, '');

      getObjectsFromPath(`/${value}`)
        .then(objects => {
          formik.setFieldValue('outputFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('outputFolderPath').then(() => setLoadingOutputFolderObjects(false));
            });
        });
    }
  }, [formik.values.outputFolderPath]);

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  let outputFolder = `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}`;
  if (formik.values.outputFolderPathSearchValue !== '') {
    outputFolder += `${formik.values.outputFolderPathSearchValue}${formik.values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
  }

  let allowCreateFolders = false;
  if (formik.errors.outputFolderPath !== undefined) {
    if (formik.errors.outputFolderPath === 'Output folder does not exist or is not a folder entity') {
      allowCreateFolders = true;
    }
  }

  const buildTooltipProject = () => {
    const project = projects.find(item => item.projectId === formik.values.projectId);

    if (project !== undefined) {
      return (
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Project ID
            </Grid>
            <Grid item xs={6}>
              {project.projectId}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Project Name
            </Grid>
            <Grid item xs={6}>
              {project.projectName}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Branch ID
            </Grid>
            <Grid item xs={6}>
              {project.branchId}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Project Name
            </Grid>
            <Grid item xs={6}>
              {project.branchName}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Language ID
            </Grid>
            <Grid item xs={6}>
              {project.languageId}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Language Code
            </Grid>
            <Grid item xs={6}>
              {project.languageCode}
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return undefined;
  };

  useEffect(() => {
    if (open) {
      if (enabledService) {
        setLoadingProjects(true);
        setProjects([]);

        dispatch(
          fetchBlueStreamProjects(
            formik.values.baseUrl,
            formik.values.username,
            formik.values.accessToken,
          ),
        )
          .then(setProjects)
          .finally(() => setLoadingProjects(false));
      } else {
        formik.setFieldValue('projectId', undefined);
        setProjects([]);

        formik.setFieldValue('projectRootMaps', undefined);
        setProjectRootMaps([]);
      }
    }
  }, [open, enabledService]);

  useEffect(() => {
    if (open && enabledService) {
      if (formik.values.projectId !== undefined) {
        setLoadingProjectRootMaps(true);
        setProjectRootMaps([]);

        dispatch(
          fetchBlueStreamProjectRootMaps(
            formik.values.baseUrl,
            formik.values.username,
            formik.values.accessToken,
            formik.values.projectId,
          ),
        )
          .then(setProjectRootMaps)
          .finally(() => setLoadingProjectRootMaps(false));
      }
    }
  }, [open, enabledService, formik.values.projectId]);

  const config = {
    title: 'BlueStream XDocs Export',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'General settings',
        fields: [
          {
            onChange: e => formik.handleChange(e),
            value: formik.values.baseUrl,
            error: formik.errors.baseUrl,
            placeholder: 'Base URL',
            tooltip: 'Base URL',
            label: 'Base URL',
            key: 'baseUrl',
          },
          {
            onChange: e => formik.handleChange(e),
            value: formik.values.username,
            error: formik.errors.username,
            placeholder: 'Username',
            tooltip: 'Username',
            label: 'Username',
            key: 'username',
          },
          {
            onChange: e => formik.handleChange(e),
            value: formik.values.accessToken,
            error: formik.errors.accessToken,
            placeholder: 'Access Token',
            tooltip: 'Access Token',
            label: 'Access Token',
            key: 'accessToken',
            type: 'password',
          },
        ],
        isValid: formik.values.baseUrl !== '' && formik.values.username !== '' && formik.values.accessToken !== '',
        async onSubmit() {
          setFirstStepError('');
          setEnabledService(false);

          const value = await fetchBlueStreamPing(
            formik.values.baseUrl,
            formik.values.username,
            formik.values.accessToken,
          );

          if (value) setEnabledService(true);
          else setFirstStepError('Unable to connect to the server. Please check if server is running and credentials are correct.');

          return value;
        },
        error: firstStepError,
        allowContinue: true,
        loading: false,
      },
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Task details',
        fields: [
          {
            item: item => (
              <MenuItem key={item.projectId} value={item.projectId}>
                {`(id: ${item.projectId}) ${item.projectName}`}
              </MenuItem>
            ),
            items: projects.sort((a, b) => b.projectId - a.projectId),
            onChange: event => formik.handleChange(event),
            tooltip: buildTooltipProject(),
            value: formik.values.projectId,
            error: formik.errors.projectId,
            placeholder: 'Project',
            label: 'Project',
            key: 'projectId',
          },
          {
            item: item => (
              <MenuItem key={item.name} value={item.name}>
                <Checkbox
                  checked={(formik.values.projectRootMaps || []).indexOf(item.name) > -1}
                  style={{ padding: '0 9px 0 0' }}
                />
                <ListItemText primary={item.title} />
              </MenuItem>
            ),
            items: projectRootMaps.sort((a, b) => a.name - b.name),
            onChange: event => {
              const { value } = event.target;
              if (Array.isArray(value) && value[value.length - 1] === 'all') {
                setTimeout(
                  () => {
                    const currentValue = (formik.values.projectRootMaps || []);

                    let listValue = projectRootMaps.map(r => r.name);
                    if (
                      currentValue.length === projectRootMaps.length
                      || (currentValue.length !== 0
                        && currentValue.length !== projectRootMaps.length)
                    ) {
                      listValue = [];
                    }

                    formik.setFieldValue('projectRootMaps', [...listValue]);
                  },
                  0,
                );
                return;
              }

              formik.handleChange(event);
            },
            value: formik.values.projectRootMaps || [],
            error: formik.errors.projectRootMaps,
            placeholder: 'Project Root Map',
            label: 'Project Root Map',
            key: 'projectRootMaps',
            enableSelectAll: true,
            multiple: true,
          },
        ],
        allowContinue: formik.values.projectId !== ''
          && (
            Array.isArray(formik.values.projectRootMaps)
            && formik.values.projectRootMaps.length > 0
          ),
        isValid: formik.values.projectId !== '' && formik.errors.projectId === undefined,
        loading: loadingProjects || loadingProjectRootMaps,
        onSubmit: () => Promise.resolve(true),
      },
      {
        title: 'Output file path',
        type: STEP_FIELD_TYPE_PATH,
        fields: [
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.outputFilename,
            error: formik.errors.outputFilename,
            placeholder: 'Example.zip',
            label: 'Output file name',
            key: 'outputFilename',
          },
        ],
        pathField: {
          label: 'Output folder path',
          placeholder: 'Search a folder by name',
          objects: filterObjects(
            formik.values.outputFolderPathObjects, formik.values.outputFolderPathSearchValue,
          ).filter(o => o.type === 'folder'),
          onChange: value => {
            onChangePathValue(value, 'outputFolderPath', 'outputFolderPathSearchValue');
            formik.setFieldValue('createFolders', false);
          },
          value: formik.values.outputFolderPath !== ''
            ? `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}${formik.values.outputFolderPathSearchValue}`
            : formik.values.outputFolderPathSearchValue,
        },
        configurations: allowCreateFolders ? [
          {
            tooltip: (
              <p>
                <p>
                  The output folder does not exist. If you select this parameter,
                  the folder will be created automatically.
                </p>
                <p>
                  <b>Notice. </b>
                  Use this parameter carefully.
                </p>
                <p>
                  THE PARENT FOLDER MUST EXIST.
                </p>
              </p>
            ),
            onChange: event => formik.handleChange(event),
            value: formik.values.createFolders,
            label: 'Create Folders',
            key: 'createFolders',
          },
        ] : [],
        allowContinue: formik.values.outputFolderPath !== '' && formik.values.outputFilename !== ''
          && (formik.errors.outputFolderPath === undefined || formik.values.createFolders),
        isValid: formik.values.outputFolderPath !== ''
          && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
        onSubmit: () => Promise.resolve(true),
        loading: creatingTask || loadingOutputFolderObjects,
        error: formik.errors.outputFolderPath,
        warning: outputFileWarning,
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    loading: creatingTask,
    initActiveStep: 0,
  };

  useEffect(() => {
    if (open && formik.values.baseUrl !== '') formik.validateField('baseUrl');
  }, [formik.values.baseUrl]);

  useEffect(() => {
    if (open && formik.values.username !== '') formik.validateField('username');
  }, [formik.values.username]);

  useEffect(() => {
    if (open && formik.values.accessToken !== '') formik.validateField('accessToken');
  }, [formik.values.accessToken]);

  // useEffect(() => {
  //   if (open && formik.values.projectId !== '') formik.validateField('projectId');
  // }, [formik.values.projectId]);

  // useEffect(() => {
  //   if (open && formik.values.projectRootMaps !== '') formik.validateField('projectRootMaps');
  // }, [formik.values.projectRootMaps]);

  useEffect(() => {
    const fileId = `${outputFolder}${formik.values.outputFilename}`;

    if (formik.values.outputFolderPath === outputFolder) {
      const exist = formik.values.outputFolderPathObjects.some(file => file.id === fileId);

      if (exist) setOutputFileWarning('The output file already exists');
      else setOutputFileWarning(undefined);
    } else {
      dispatch(checkFileExist(fileId))
        .then(() => setOutputFileWarning('The output file already exists'))
        .catch(() => setOutputFileWarning(undefined));
    }
  }, [
    formik.values.outputFolderPath,
    formik.values.outputFolderPathSearchValue,
    formik.values.outputFolderPathObjects,
    formik.values.outputFilename,
  ]);

  useEffect(() => {
    if (open && formik.values.outputFilename !== '') formik.validateField('outputFilename');
  }, [formik.values.outputFilename]);

  return (
    <>
      <CreateTaskDialog
        open={open}
        config={config}
        onClose={() => {
          onClose();

          if (resetOnClose) {
            setTaskType('');
            formik.setValues(generateInitValues({}));
          }
        }}
        resetOnClose={resetOnClose}
      />
    </>
  );
}

CreateBlueStreamDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreateBlueStreamDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreateBlueStreamDialog;
