import { ENABLED_TASKS } from '../config';

export const ACTION_TYPE_CHECKBOX = 'checkbox';
export const ACTION_TYPE_RADIO = 'radio';
export const ACTION_TYPE_NONE = 'none';
export const ACTION_TYPES = [ACTION_TYPE_CHECKBOX, ACTION_TYPE_RADIO, ACTION_TYPE_NONE];

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];
export const ROWS_PER_PAGE_DEFAULT = 10;

export const BACK_PATHNAME_COOKIE_NAME = 'backPathname';

export const FILE_STORAGE_ROUTE = '/file-storage';
export const REPORTING_ROUTE = '/reporting';
export const REPORTING_ROUTE_CONVERSION_RESULTS = '/reporting-conversion-results';
export const REPORTING_ROUTE_VALIDATION_REPORTS = '/reporting-validation-reports';
export const REPORTING_ROUTE_HEALTH_CHECK = '/reporting-health-check';
export const REPORTING_ROUTE_REUSE_OPPORTUNITIES = '/reporting-result-opportunities';

export const TASKS_ROUTE = '/tasks';
export const CREATE_TASK_ROUTE = `${TASKS_ROUTE}/create`;
export const CONVERSIONS_ROUTE = '/conversions';

export const VALIDATIONS_ROUTE = '/validations';
export const REPORTS_ROUTE = '/reports';
export const PREFERENCES_ROUTE = '/preferences';
export const OXYGEN_COMPLETENESS_CHECK_ROUTE = '/oxygen-completeness-check';
export const OXYGEN_BATCH_CONVERTER_ROUTE = '/oxygen-batch-converter';
export const OXYGEN_METRIC_REPORT_ROUTE = '/oxygen-metric-report';
export const ADMIN_REGRESSION_ROUTE = '/admin-regression';
export const ADMIN_USERS_ROUTE = '/admin-users';
export const ADMIN_PACKAGES_ROUTE = '/admin-packages';

export const KION_SDL_ALLOWED_OBJECT_DOMAINS = ['Technical', 'Diagnostic'];

export const SDL_ALLOWED_LANGUAGES = [
  'ar-AE', 'de-DE', 'en-GB', 'en-US', 'es-ES', 'es-MX', 'fr-CA', 'fr-FR', 'it-IT', 'ja-JP', 'ko-KR', 'nl-NL', 'zh-CN',
];

export const SDL_ALLOWED_IMAGE_RESOLUTIONS = [
  'High', 'Low', 'Multimedia', 'Source', 'Thumbnail',
];

export const DATE_FORMAT = 'yyyy-MM-dd';

export const TYPE_GITHUB = 'GitHub';
export const TYPE_BLUESTREAM = 'BlueStream XDocs';
export const TYPE_FLUID_TOPICS = 'Fluid Topics';
export const TYPE_SDL = 'Tridion';
export const TYPE_KION_SDL = 'Kion Tridion';
export const TYPE_PALIGO = 'Paligo';
export const TYPE_PANDOC = 'Pandoc';
export const TYPE_IXIASOFT = 'Ixiasoft';
export const TYPE_CONVERSION = 'Conversion';
export const TYPE_NORMALIZATION = 'Normalization';

export const STATUSES_FILTER = {
  // UNKNOWN: 'Unknown',
  // PROVISIONING: 'Provisioning',
  PENDING: 'Pending',
  RUNNING: 'Running',
  INTERRUPTED: 'Interrupted',
  // DEPROVISIONING: 'Deprovisioning',
  FINISHED: 'Finished',
};

const TASKS_TYPES = [
  {
    key: TYPE_CONVERSION,
    enabled: ENABLED_TASKS.conversion,
  },
  {
    key: TYPE_NORMALIZATION,
    enabled: ENABLED_TASKS.normalization,
  },
  {
    key: TYPE_PANDOC,
    enabled: ENABLED_TASKS.pandoc,
  },
  {
    key: TYPE_IXIASOFT,
    enabled: ENABLED_TASKS.ixiasoft,
  },
  {
    key: TYPE_PALIGO,
    enabled: ENABLED_TASKS.paligo,
  },
  {
    key: TYPE_SDL,
    enabled: ENABLED_TASKS.sdl,
  },
  {
    key: TYPE_KION_SDL,
    enabled: ENABLED_TASKS.kion_sdl,
  },
  {
    key: TYPE_FLUID_TOPICS,
    enabled: ENABLED_TASKS.fluidTopics,
  },
  {
    key: TYPE_GITHUB,
    enabled: ENABLED_TASKS.gitHub,
  },
  {
    key: TYPE_BLUESTREAM,
    enabled: ENABLED_TASKS.bluestream,
  },
];

export const TYPES_FILTER = TASKS_TYPES.filter(i => i.enabled).map(i => i.key);

export const AUTOMATIC_REPORTS_MAPS = [
  {
    name: 'Elements information report',
    key: 'elements-information-report',
  },
  {
    name: 'IDs not referenced report',
    key: 'ids-not-referenced-report',
  },
  {
    name: 'Required cleanup report',
    key: 'required-cleanup-report',
  },
  {
    name: 'Conref targets report',
    key: 'conref-targets-report',
  },
  {
    name: 'The \'ph\' id content report',
    key: 'ph-id-content-report',
  },
  {
    name: 'Info types report',
    key: 'info-types-report',
  },
  {
    name: 'Manifest report',
    key: 'manifest-report',
  },
  // {
  //   name: 'Reuse report',
  //   key: 'reuse-report',
  // },
];

export const EVALUATION_ROOT_CATEGORIES = [
  {
    name: 'Stylistically Consistent',
    key: 'stylistic',
  },
  {
    name: 'Scalable And Modular',
    key: 'scalable_and_modular',
  },
  {
    name: 'Minimalist',
    key: 'minimalist',
  },
  {
    name: 'Semantic *',
    key: 'semantic',
  },
];
export const DEFAULT_ROOT_CATEGORIES = EVALUATION_ROOT_CATEGORIES.map(c => c.key);

export const EVALUATION_MINIMALIST_CATEGORIES = [
  {
    name: 'Action-Oriented',
    key: 'action_oriented',
  },
  {
    name: 'Troubleshooting',
    key: 'troubleshooting',
  },
  {
    name: 'Goal-and-task',
    key: 'goal_and_task',
  },
  {
    name: 'Findability',
    key: 'findability',
  },
];
export const DEFAULT_MINIMALIST_CATEGORIES = EVALUATION_MINIMALIST_CATEGORIES.map(c => c.key);

export const EVALUATION_SCALABLE_AND_MODULAR_CATEGORIES = [
  {
    name: 'Standalone',
    key: 'standalone',
  },
  {
    name: 'Dependency',
    key: 'dependency',
  },
  {
    name: 'Modularity',
    key: 'modularity',
  },
  {
    name: 'Reuse',
    key: 'reuse',
  },
];
export const DEFAULT_SCALABLE_AND_MODULAR_CATEGORIES = (
  EVALUATION_SCALABLE_AND_MODULAR_CATEGORIES.map(c => c.key)
);

export const EVALUATION_SEMANTIC_CATEGORIES = [
  {
    name: 'Information-Typed',
    key: 'information_typed',
  },
  {
    name: 'Accessibility',
    key: 'accessibility',
  },
  {
    name: 'Metadata',
    key: 'metadata',
  },
  {
    name: 'Ratio',
    key: 'ratio',
  },
];

export const DEFAULT_SEMANTIC_CATEGORIES = EVALUATION_SEMANTIC_CATEGORIES.map(c => c.key);

export const EVALUATION_STYLISTIC_CATEGORIES = [
  {
    name: 'Writing style and grammar *',
    key: 'writing_style_and_grammar',
  },
  {
    name: 'Titling conventions *',
    key: 'titling_conventions',
  },
  {
    name: 'Reading level',
    key: 'reading_level',
  },
];

export const DEFAULT_STYLISTIC_CATEGORIES = EVALUATION_STYLISTIC_CATEGORIES.map(c => c.key);
