import React from 'react';
import { Typography } from '@material-ui/core';

function ReportsReuseOpportunities() {
  return (
    <div>
      <Typography variant="h5">
        Reports page is coming soon.
      </Typography>
    </div>
  );
}

export default ReportsReuseOpportunities;
