import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Home from './dashboard/Dashboard';
import FileStorage from './fileStorage/FileStorage';
import {
  AUTH_URL, ENABLE_DASHBOARD, ENABLE_FILE_STORAGE_SERVICE, ENABLE_TASKS_SERVICE,
} from '../config';
import LoginSuccess from './auth/LoginSuccess';
import { isUserAuthenticated } from '../core/services/authService';
import NotFound from './common/NotFound';
import Tasks from './tasks/Tasks';
import CreateTask from './tasks/CreateTask';
import TaskDetails from './tasks/TaskDetails';
import Conversions from './conversions/Conversions';
import Validations from './validations/Validations';
import Reports from './reports/Reports';
import Preferences from './preferences/Preferences';
import OxygenCompletenessCheck from './oxygen/OxygenCompletenessCheck';
import OxygenBatchConverter from './oxygen/OxygenBatchConverter';
import OxygenMetricReport from './oxygen/OxygenMetricReport';
import AdminRegression from './admin/AdminRegression';
import AdminUsers from './admin/AdminUsers';
import AdminPackages from './admin/AdminPackages';

import { setCookie } from '../core/services/cookieService';
import {
  BACK_PATHNAME_COOKIE_NAME,
  CREATE_TASK_ROUTE,
  FILE_STORAGE_ROUTE,
  TASKS_ROUTE,
  CONVERSIONS_ROUTE,
  VALIDATIONS_ROUTE,
  REPORTS_ROUTE,
  PREFERENCES_ROUTE,
  OXYGEN_COMPLETENESS_CHECK_ROUTE,
  OXYGEN_BATCH_CONVERTER_ROUTE,
  OXYGEN_METRIC_REPORT_ROUTE,
  ADMIN_REGRESSION_ROUTE,
  ADMIN_USERS_ROUTE,
  ADMIN_PACKAGES_ROUTE,
  REPORTING_ROUTE_CONVERSION_RESULTS,
  REPORTING_ROUTE_VALIDATION_REPORTS,
  REPORTING_ROUTE_HEALTH_CHECK,
  REPORTING_ROUTE_REUSE_OPPORTUNITIES,
} from '../core/constants';
import ReportsConversionResults from './reports/ReportsConversionResults';
import ReportsHealthCheck from './reports/ReportsHealthCheck';
import ReportsReuseOpportunities from './reports/ReportsReuseOpportunities';
import ReportsValidationReports from './reports/ReportsValidationReports';

function Router() {
  return (
    <Switch>

      <Route path="/login/success" component={LoginSuccess} exact />

      {ENABLE_DASHBOARD && <PrivateRoute path={['/', '/home']} component={Home} exact />}

      {ENABLE_FILE_STORAGE_SERVICE && (
        <PrivateRoute path={FILE_STORAGE_ROUTE} component={FileStorage} />
      )}

      {ENABLE_TASKS_SERVICE && (
        <>
          <PrivateRoute path={TASKS_ROUTE} component={Tasks} exact />
          <PrivateRoute path={CREATE_TASK_ROUTE} component={CreateTask} exact />
          <PrivateRoute path={`${TASKS_ROUTE}/:id`} component={TaskDetails} exact />
        </>
      )}

      <PrivateRoute path={CONVERSIONS_ROUTE} component={Conversions} exact />

      <PrivateRoute path={VALIDATIONS_ROUTE} component={Validations} exact />
      <PrivateRoute path={REPORTS_ROUTE} component={Reports} exact />

      <PrivateRoute path={PREFERENCES_ROUTE} component={Preferences} exact />
      <PrivateRoute
        path={REPORTING_ROUTE_CONVERSION_RESULTS}
        component={ReportsConversionResults}
        exact
      />
      <PrivateRoute
        path={REPORTING_ROUTE_VALIDATION_REPORTS}
        component={ReportsHealthCheck}
        exact
      />
      <PrivateRoute
        path={REPORTING_ROUTE_HEALTH_CHECK}
        component={ReportsReuseOpportunities}
        exact
      />
      <PrivateRoute
        path={REPORTING_ROUTE_REUSE_OPPORTUNITIES}
        component={ReportsValidationReports}
        exact
      />
      <PrivateRoute
        path={OXYGEN_COMPLETENESS_CHECK_ROUTE}
        component={OxygenCompletenessCheck}
        exact
      />
      <PrivateRoute path={OXYGEN_BATCH_CONVERTER_ROUTE} component={OxygenBatchConverter} exact />
      <PrivateRoute path={OXYGEN_METRIC_REPORT_ROUTE} component={OxygenMetricReport} exact />

      <PrivateRoute path={ADMIN_REGRESSION_ROUTE} component={AdminRegression} exact />
      <PrivateRoute path={ADMIN_USERS_ROUTE} component={AdminUsers} exact />
      <PrivateRoute path={ADMIN_PACKAGES_ROUTE} component={AdminPackages} exact />

      <PrivateRoute path="*" component={NotFound} />

    </Switch>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (isUserAuthenticated() ? (
        <Component {...props} />
      ) : (
        <RedirectToAuth />
      ))}
    />
  );
}

PrivateRoute.propTypes = { component: PropTypes.any.isRequired };

function RedirectToAuth() {
  const location = useLocation();

  setCookie(BACK_PATHNAME_COOKIE_NAME, location.pathname, { path: '/', 'max-age': 600 });

  return (
    <>
      {window.location.replace(AUTH_URL)}
    </>
  );
}

export default Router;
