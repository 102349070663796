import React from 'react';
import { Typography } from '@material-ui/core';

function ReportsHealthCheck() {
  return (
    <div>
      <Typography variant="h5">
        Reports page is coming soon.
      </Typography>
    </div>
  );
}

export default ReportsHealthCheck;
