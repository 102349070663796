import axios from 'axios';
import { getToken, isUserAuthenticated } from './core/services/authService';

export const APP_ENV = process.env.REACT_APP_ENV || 'dev';

const config = {
  collegeBoard: {
    AUTH_URL: 'https://ryffine-user-pool-college-board.auth.us-east-1.amazoncognito.com/login?client_id=7o6bldvkdk7m56jessvf4643nk&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://college-board.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.college-board.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.college-board.content-ryffiner.io',
    IAM_API_URL: 'https://iam.college-board.content-ryffiner.io',
  },
  amd: {
    AUTH_URL: 'https://ryffine-user-pool-amd.auth.us-east-1.amazoncognito.com/login?client_id=17mhcip17d64kbluvptu2dkfbv&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://amd.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.amd.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.amd.content-ryffiner.io',
    IAM_API_URL: 'https://iam.amd.content-ryffiner.io',
  },
  elinicalworks: {
    AUTH_URL: 'https://ryffine-user-pool-elinicalworks.auth.us-east-1.amazoncognito.com/login?client_id=5stvocp7f1dauckjoec9i7bf54&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://elinicalworks.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.elinicalworks.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.elinicalworks.content-ryffiner.io',
    IAM_API_URL: 'https://iam.elinicalworks.content-ryffiner.io',
  },
  omron: {
    AUTH_URL: 'https://ryffine-user-pool-omron.auth.us-east-1.amazoncognito.com/login?client_id=4tegdtbt1ccdrus6md4ngmill6&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://omron.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.omron.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.omron.content-ryffiner.io',
    IAM_API_URL: 'https://iam.omron.content-ryffiner.io',
  },
  demo: {
    AUTH_URL: 'https://ryffine-user-pool-demo.auth.us-east-1.amazoncognito.com/login?client_id=65ob2lvgu84ur1aov5c9ri9kou&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://demo.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.demo.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.demo.content-ryffiner.io',
    IAM_API_URL: 'https://iam.demo.content-ryffiner.io',
  },
  dev: {
    AUTH_URL: 'https://ryffine-user-pool-dev.auth.us-east-1.amazoncognito.com/login?client_id=51lcumd2n8i5018059ic5n91tv&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://dev.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.dev.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.dev.content-ryffiner.io',
    IAM_API_URL: 'https://iam.dev.content-ryffiner.io',
  },
  rocket: {
    AUTH_URL: 'https://ryffine-user-pool-rocket.auth.us-east-1.amazoncognito.com/login?client_id=5rjdovmuca3s7e405rl1c5188n&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://rocket.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.rocket.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.rocket.content-ryffiner.io',
    IAM_API_URL: 'https://iam.rocket.content-ryffiner.io',
  },
  kla: {
    AUTH_URL: 'https://ryffine-user-pool-kla.auth.us-east-1.amazoncognito.com/login?client_id=4dl8sojc0knng5gm82bfes58oj&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://kla.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.kla.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.kla.content-ryffiner.io',
    IAM_API_URL: 'https://iam.kla.content-ryffiner.io',
  },
  kion: {
    AUTH_URL: 'https://ryffine-user-pool-kion.auth.us-east-1.amazoncognito.com/login?client_id=2vuk7b87sr218cklitv5g058k7&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://kion.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.kion.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.kion.content-ryffiner.io',
    IAM_API_URL: 'https://iam.kion.content-ryffiner.io',
  },
  ixiasoft: {
    AUTH_URL: 'https://ryffine-user-pool-ixiasoft.auth.us-east-1.amazoncognito.com/login?client_id=784e7rhins9c4s01nl57p20tpd&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://ixiasoft.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.ixiasoft.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.ixiasoft.content-ryffiner.io',
    IAM_API_URL: 'https://iam.ixiasoft.content-ryffiner.io',
  },
  uat: {
    AUTH_URL: 'https://ryffine-user-pool-uat.auth.us-east-1.amazoncognito.com/login?client_id=7laq7uf4dh8keto37n0acckthi&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://uat.content-ryffiner.io/login/success',
    FILES_API_URL: 'https://filestorage.uat.content-ryffiner.io',
    TASKS_API_URL: 'https://conversion.uat.content-ryffiner.io',
    IAM_API_URL: 'https://iam.uat.content-ryffiner.io',
  },
  local: {
    AUTH_URL: 'https://ryffine-user-pool-dev.auth.us-east-1.amazoncognito.com/login?client_id=51lcumd2n8i5018059ic5n91tv&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://dev.content-ryffiner.io/login/success',
    FILES_API_URL: 'http://localhost:5000/api/v1',
    TASKS_API_URL: 'http://localhost:5000/api/v1',
    IAM_API_URL: 'http://localhost:5000/api/v1',
  },
};

export const ENABLE_DASHBOARD = !['omron', 'elinicalworks'].includes(APP_ENV);
export const ENABLE_TASKS_SERVICE = !['omron', 'elinicalworks'].includes(APP_ENV);
export const ENABLE_FILE_STORAGE_SERVICE = true;
export const ENABLE_REPORTING_SERVICE = true;

export const DEFAULT_BACK_PATHNAME = ['omron', 'elinicalworks'].includes(APP_ENV) ? '/file-storage' : undefined;

export const ENABLE_OXYGEN_CONVERSIONS = !['rocket', 'kion', 'amd', 'collegeBoard'].includes(APP_ENV);

export const { AUTH_URL } = config[APP_ENV];
export const { IAM_API_URL } = config[APP_ENV];
export const { FILES_API_URL } = config[APP_ENV];
export const { TASKS_API_URL } = config[APP_ENV];

export const XSLT_CONVERSION_TYPES_API_URL = `${FILES_API_URL}/path/repo-xsl-converters`;
export const PYTHON_CONVERSION_TYPES_API_URL = `${FILES_API_URL}/path/repo-python-converters`;

export function configureAxios() {
  if (isUserAuthenticated()) {
    axios.defaults.headers.common.Authorization = getToken();
  }
}

export const ENABLED_TASKS = {
  kion_sdl: ['kion', 'dev'].includes(APP_ENV),
  bluestream: ['collegeBoard', 'dev'].includes(APP_ENV),
  sdl: !['kion', 'demo', 'amd', 'rocket', 'kla', 'ixiasoft', 'collegeBoard'].includes(APP_ENV),
  paligo: !['kion', 'amd', 'rocket', 'kla', 'ixiasoft', 'collegeBoard'].includes(APP_ENV),
  pandoc: !['kion', 'demo', 'amd', 'rocket', 'collegeBoard'].includes(APP_ENV),
  ixiasoft: !['kion', 'amd', 'rocket', 'kla', 'ixiasoft', 'collegeBoard'].includes(APP_ENV),
  conversion: true,
  normalization: !['kion', 'amd', 'ixiasoft', 'collegeBoard'].includes(APP_ENV),
  fluidTopics: !['kion', 'demo', 'amd', 'rocket', 'kla', 'ixiasoft', 'collegeBoard'].includes(APP_ENV),
  gitHub: !['kion', 'demo', 'amd', 'rocket', 'kla', 'ixiasoft', 'collegeBoard'].includes(APP_ENV),
  zoomin: ['uat', 'dev'].includes(APP_ENV),
};

export const CONVERSION_TITLE = APP_ENV === 'collegeBoard' ? 'Report' : 'Conversion'; // TODO: for now this just replaces the conversions dialog for the sake of better demo. Implemented in scope of ticket: RCR20-114

export const ENABLE_OLD_CREATE_CONVERSION = false;
export const SHOW_TAG_LAST_MODIFIED_DATE = true;

export const SECONDARY_UI_COLOR = '#DB7E28';
export const MAIN_UI_COLOR = '#1C355E';
